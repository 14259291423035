/** @format */

import {
	AspectRatio,
	Box,
	ChakraProps,
	Container,
	Flex,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
} from '@chakra-ui/react';
import Image from 'next/image';
import {textStyles} from '../../styles/js/textStyles';

interface TableRow {
	column1: string;
	column2: {
		wysiwyg: string;
		image?: {
			sourceUrl: string;
			altText: string;
			mediaDetails: {
				width: number;
				height: number;
			};
		};
	};
}

type TableType = {
	headings: string[];
	rows: TableRow[];
};

export interface TableComponentProps {
	sectionHeading: string;
	table: TableType;
}

const cellStyle: ChakraProps = {
	py: 6,
	px: {base: 2, md: 4},
};

const TableComponent: React.FC<TableComponentProps> = (
	data: TableComponentProps,
) => {
	const {table, sectionHeading} = data;
	const view = useBreakpointValue({base: 'mobile', md: 'desktop'}, {ssr: true});

	return (
		<Box as='section' position='relative' my={{base: 12, md: 16}}>
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Text as='h3' {...textStyles.textxl} pb={{base: 6, md: 8, lg: 14}}>
					{sectionHeading}
				</Text>

				<Table>
					<Thead>
						{Array.isArray(table.headings) &&
							table.headings.length > 0 &&
							table.headings.map((heading, index) => (
								<Th key={index} py={4} px={{base: 2, md: 4}}>
									<Text {...textStyles.headingsm}>{heading}</Text>
								</Th>
							))}
					</Thead>
					<Tbody>
						{table.rows.map((row, index) => (
							<Tr key={index}>
								<Td w={{base: '100%', md: '33%'}} {...cellStyle}>
									<Box
										className='WYSIWYG--default'
										dangerouslySetInnerHTML={{__html: row.column1}}
									/>
									{view === 'mobile' ? (
										<Box bg='grey.50' {...cellStyle}>
											<ImageTextFlex row={row} />
										</Box>
									) : null}
								</Td>

								{view !== 'mobile' ? (
									<Td bg='grey.50' {...cellStyle}>
										<ImageTextFlex row={row} />
									</Td>
								) : null}
							</Tr>
						))}
					</Tbody>
				</Table>
			</Container>
		</Box>
	);
};

export default TableComponent;

const ImageTextFlex = ({row}) => {
	return (
		<Flex direction={{base: 'column', md: 'row'}}>
			<Box
				className='WYSIWYG--default'
				dangerouslySetInnerHTML={{__html: row.column2.wysiwyg}}
			/>
			{row.column2?.image?.sourceUrl && (
				<AspectRatio
					ratio={3 / 2}
					w={{base: '200px', md: '342px'}}
					mt={{base: 6, sm: 0}}
					ml={{base: 0, md: 'auto'}}>
					<Image
						src={row.column2.image.sourceUrl}
						alt={row.column2.image.altText}
						fill
					/>
				</AspectRatio>
			)}
		</Flex>
	);
};
