import DOMPurify from "isomorphic-dompurify";
import { removeTypenameProp } from "../../utils";

export default (data: any) => {
    if (!data) return null;
    const cleanedQuery = removeTypenameProp(data);

    let { accordions } = cleanedQuery;

    return {
        accordions: accordions.map((accordionItem) => {
            const { title = "", descriptions = [] } = accordionItem;

            const data = {
                title,
                wysiwygs: descriptions.map((description) => {
                    return { __html: DOMPurify.sanitize(description.wysiwyg) };
                }),
            };

            return data;
        }),
    };
};
