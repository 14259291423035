/** @format */

import {getMedia} from '../Image/imageMapping';

export default (data: any) => {
	if (!data) return null;

	const {
		subtitle = '',
		title = '',
		background = '',
		titleCategory = '',
		fullwidth,
		backgroundColorGroup: {backgroundColour},
		backgroundVideoGroup,
	} = data;

	return {
		fullWidth: fullwidth === 'true',
		backgroundType: background,
		backgroundColor: backgroundColour,
		subtitles: [subtitle],
		title,
		titleCategory,
		image: getMedia(data.backgroundImage),
		video: getMedia(backgroundVideoGroup),
		videoThumbnail: getMedia(data.backgroundVideoGroup?.videoThumbnail),
	};
};
