import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { SpacerComponentProps } from "./SpacerComponentProps";

function SpacerComponent({
    size,
    backgroundColor,
    display,
}: SpacerComponentProps): ReactElement {
    let margin;
    switch (size) {
        case "lg":
            margin = { base: "16", md: "20" };
            break;
        case "md":
            margin = { base: "10", md: "12" };
            break;
        case "sm":
            margin = { base: "1.5", md: "2" };
            break;
        // case "xs":
        //     margin = { base: "1.5", md: "2" };
        //     break;
        default:
            margin = { base: "6", md: "8" };
            break;
    }

    return (
        <Box
            className={`spacer-size-${size}`}
            paddingTop={margin}
            bgColor={backgroundColor || "arctic"}
            display={display}
        />
    );
}

export default SpacerComponent;
