import DOMPurify from "isomorphic-dompurify";
import { getButtonMapping, removeTypenameProp } from "../../utils";

export const richTextCtaMapping = (data: any) => {
    if (!data) return "";
    let cleanedQuery = removeTypenameProp(data);
    let buttonGroup = [];

    const {
        richText: { wysiwyg = "", columnOffset = "0" },
        addButtons,
        buttonGroup: { buttons } = { buttons: [] },
    } = cleanedQuery;

    if (addButtons && buttons.length) {
        buttonGroup = buttons
            .map((item) => getButtonMapping(item.buttonGroup))
            .flat();
    }

    const wysiwygData = {
        html: { __html: DOMPurify.sanitize(wysiwyg) },
        columnOffset: columnOffset ? +columnOffset : 1,
        buttons: buttonGroup,
    };

    return wysiwygData;
};
