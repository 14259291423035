/** @format */

export const getMedia = media => {
	if (!media) return null;
	if (media.hasOwnProperty('video')) {
		const {videoThumbnail, video} = media;
		const videoItem =
			video && video[0]
				? {
						alt: video[0].altText || '',
						mediaItemUrl: video[0].mediaItemUrl,
				  }
				: null;
		return {
			videoThumbnail: getMedia(videoThumbnail),
			video: videoItem,
		};
	} else if (media.hasOwnProperty('altText')) {
		return getMediaProps(media);
	} else if (media?.image?.hasOwnProperty('altText')) {
		return getMediaProps(media.image);
	}

	return null;
};

const getMediaProps = media => {
	// Fix to catch null mediaDetails
	let width = '100%';
	let height = 'auto';

	const {altText: alt = '', sourceUrl: src} = media;
	if (media.mediaDetails) {
		width = media.mediaDetails.width;
		height = media.mediaDetails.height;
	}

	// Create a new flat image object
	return {alt, height, width, src};
};
