import { TableComponentProps } from ".";

const tableMapping = (data: any): TableComponentProps => {
    const { sectionHeading, table } = data;
    const headings = Array.isArray(table.headings) && table.headings.map((heading: any) => heading.heading);
    const rows = table.rows.map((row: any) => {
        const { column1, column2 } = row;
        return {
            column1,
            column2
        }
    }
    );
    return {
        sectionHeading,
        table: {
            headings,
            rows
        }
    }
}

export default tableMapping;