
export type Tabs = {
    formInfo: FormInfo,
    rows: Rows[]
}

export type FormInfo = {
    heading: string,
    subheading: string,
}

export type Rows = {
    title?: string,
    fields: Fields[]
}

export type Fields = {
    title?: string,
    type: string,
    placeholder?: string,
    required: boolean,
    validation?: string,
    choices?: {choice: string},
}

export type Form = {
    sender: string,
    tabs: Tabs[],
}

const flexibleFormMapping = (data: Form) => {
    return data;
};

export default flexibleFormMapping;
