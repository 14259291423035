import { SpacerProps } from "./SpacerProps";

export const spacerMapping = (data: any): SpacerProps | null => {
  if (!data) return null;

  return {
    size: data.size,
    backgroundColor: data.backgroundColor,
  };
};
