import React from "react";

const LogoIcon = ({ fill = "" }) => {
    return (
        <svg
            width="64"
            height="80"
            viewBox="0 0 64 80"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.1859 69.7924C21.8257 72.2588 27.0846 75.053 31.3899 79.3834L32.0019 80L32.6139 79.3834C36.9334 75.0316 42.1994 72.2374 46.8463 69.7746L46.8783 69.7567C52.5606 66.7415 57.4673 64.1397 60.6055 59.4137C62.897 55.9672 64.0071 51.6404 64 46.1837V0H0.000210425V46.198C-0.0175801 51.6439 1.09255 55.9672 3.39465 59.4208C6.53646 64.1361 11.4573 66.7486 17.1539 69.7781L17.1859 69.796V69.7924ZM32.0019 37.9186L4.36246 57.6958C2.57984 54.6627 1.71878 50.8919 1.73301 46.198V40.916L32.0054 19.2569L62.2778 40.916V46.1873C62.285 50.8884 61.4203 54.6627 59.6448 57.6922L32.0054 37.915L32.0019 37.9186ZM32.0019 17.1255L1.72946 38.7846V1.73216H62.2707V38.7882L31.9983 17.1291L32.0019 17.1255ZM46.0386 68.242C41.4949 70.6513 36.3748 73.3636 32.0019 77.5586C27.6432 73.385 22.5302 70.6692 17.9971 68.2598L17.9651 68.242C12.7703 65.4798 8.24792 63.074 5.31959 59.1357L32.0019 40.0428L58.6842 59.1357C55.7629 63.0776 51.2513 65.4762 46.0706 68.2242L46.0386 68.242Z" />
            <path d="M35.4593 66.7055C35.4593 66.2279 35.0715 65.8394 34.5947 65.8394C34.1179 65.8394 33.7301 66.2279 33.7301 66.7055H32.8655V65.8394C33.3422 65.8394 33.7301 65.451 33.7301 64.9734C33.7301 64.4958 33.3422 64.1073 32.8655 64.1073C32.8655 63.6297 32.4776 63.2412 32.0008 63.2412C31.524 63.2412 31.1362 63.6297 31.1362 64.1073C30.6594 64.1073 30.2716 64.4958 30.2716 64.9734C30.2716 65.451 30.6594 65.8394 31.1362 65.8394V66.7055H30.2716C30.2716 66.2279 29.8838 65.8394 29.407 65.8394C28.9302 65.8394 28.5424 66.2279 28.5424 66.7055C28.0656 66.7055 27.6777 67.094 27.6777 67.5716C27.6777 68.0492 28.0656 68.4377 28.5424 68.4377C28.5424 68.9153 28.9302 69.3038 29.407 69.3038C29.8838 69.3038 30.2716 68.9153 30.2716 68.4377H31.1362V70.9718C30.6594 70.9718 30.2716 71.3603 30.2716 71.8378C30.2716 72.3154 30.6594 72.7039 31.1362 72.7039C31.1362 72.7039 31.1398 72.7039 31.1433 72.7039C31.1433 72.7253 31.1362 72.7467 31.1362 72.7681C31.1362 73.2457 31.524 73.6342 32.0008 73.6342C32.4776 73.6342 32.8655 73.2457 32.8655 72.7681C32.8655 72.7467 32.8619 72.7253 32.8583 72.7039C32.8583 72.7039 32.8619 72.7039 32.8655 72.7039C33.3422 72.7039 33.7301 72.3154 33.7301 71.8378C33.7301 71.3603 33.3422 70.9718 32.8655 70.9718V68.4377H33.7301C33.7301 68.9153 34.1179 69.3038 34.5947 69.3038C35.0715 69.3038 35.4593 68.9153 35.4593 68.4377C35.9361 68.4377 36.3239 68.0492 36.3239 67.5716C36.3239 67.094 35.9361 66.7055 35.4593 66.7055Z" />
            <path d="M35.4593 47.6489C35.4593 47.1713 35.0715 46.7828 34.5947 46.7828C34.1179 46.7828 33.7301 47.1713 33.7301 47.6489H32.8655V46.7828C33.3422 46.7828 33.7301 46.3943 33.7301 45.9167C33.7301 45.4391 33.3422 45.0506 32.8655 45.0506C32.8655 44.5731 32.4776 44.1846 32.0008 44.1846C31.524 44.1846 31.1362 44.5731 31.1362 45.0506C30.6594 45.0506 30.2716 45.4391 30.2716 45.9167C30.2716 46.3943 30.6594 46.7828 31.1362 46.7828V47.6489H30.2716C30.2716 47.1713 29.8838 46.7828 29.407 46.7828C28.9302 46.7828 28.5424 47.1713 28.5424 47.6489C28.0656 47.6489 27.6777 48.0374 27.6777 48.515C27.6777 48.9926 28.0656 49.381 28.5424 49.381C28.5424 49.8586 28.9302 50.2471 29.407 50.2471C29.8838 50.2471 30.2716 49.8586 30.2716 49.381H31.1362V51.9151C30.6594 51.9151 30.2716 52.3036 30.2716 52.7812C30.2716 53.2588 30.6594 53.6473 31.1362 53.6473C31.1362 53.6473 31.1398 53.6473 31.1433 53.6473C31.1433 53.6687 31.1362 53.6901 31.1362 53.7114C31.1362 54.189 31.524 54.5775 32.0008 54.5775C32.4776 54.5775 32.8655 54.189 32.8655 53.7114C32.8655 53.6901 32.8619 53.6687 32.8583 53.6473C32.8583 53.6473 32.8619 53.6473 32.8655 53.6473C33.3422 53.6473 33.7301 53.2588 33.7301 52.7812C33.7301 52.3036 33.3422 51.9151 32.8655 51.9151V49.381H33.7301C33.7301 49.8586 34.1179 50.2471 34.5947 50.2471C35.0715 50.2471 35.4593 49.8586 35.4593 49.381C35.9361 49.381 36.3239 48.9926 36.3239 48.515C36.3239 48.0374 35.9361 47.6489 35.4593 47.6489Z" />
            <path d="M24.2171 56.309C24.2171 55.8315 23.8293 55.443 23.3525 55.443C22.8757 55.443 22.4879 55.8315 22.4879 56.309H21.6233V55.443C22.1001 55.443 22.4879 55.0545 22.4879 54.5769C22.4879 54.0993 22.1001 53.7108 21.6233 53.7108C21.6233 53.2332 21.2354 52.8447 20.7587 52.8447C20.2819 52.8447 19.894 53.2332 19.894 53.7108C19.4172 53.7108 19.0294 54.0993 19.0294 54.5769C19.0294 55.0545 19.4172 55.443 19.894 55.443V56.309H19.0294C19.0294 55.8315 18.6416 55.443 18.1648 55.443C17.688 55.443 17.3002 55.8315 17.3002 56.309C16.8234 56.309 16.4355 56.6975 16.4355 57.1751C16.4355 57.6527 16.8234 58.0412 17.3002 58.0412C17.3002 58.5188 17.688 58.9073 18.1648 58.9073C18.6416 58.9073 19.0294 58.5188 19.0294 58.0412H19.894V60.5753C19.4172 60.5753 19.0294 60.9638 19.0294 61.4414C19.0294 61.9189 19.4172 62.3074 19.894 62.3074C19.894 62.3074 19.8976 62.3074 19.9011 62.3074C19.9011 62.3288 19.894 62.3502 19.894 62.3716C19.894 62.8492 20.2819 63.2377 20.7587 63.2377C21.2354 63.2377 21.6233 62.8492 21.6233 62.3716C21.6233 62.3502 21.6197 62.3288 21.6162 62.3074C21.6162 62.3074 21.6197 62.3074 21.6233 62.3074C22.1001 62.3074 22.4879 61.9189 22.4879 61.4414C22.4879 60.9638 22.1001 60.5753 21.6233 60.5753V58.0412H22.4879C22.4879 58.5188 22.8757 58.9073 23.3525 58.9073C23.8293 58.9073 24.2171 58.5188 24.2171 58.0412C24.6939 58.0412 25.0818 57.6527 25.0818 57.1751C25.0818 56.6975 24.6939 56.309 24.2171 56.309Z" />
            <path d="M46.7035 58.0451C47.1802 58.0451 47.5681 57.6566 47.5681 57.179C47.5681 56.7014 47.1802 56.3129 46.7035 56.3129C46.7035 55.8354 46.3156 55.4469 45.8388 55.4469C45.3621 55.4469 44.9742 55.8354 44.9742 56.3129H44.1096V55.4469C44.5864 55.4469 44.9742 55.0584 44.9742 54.5808C44.9742 54.1032 44.5864 53.7147 44.1096 53.7147C44.1096 53.2371 43.7218 52.8486 43.245 52.8486C42.7682 52.8486 42.3804 53.2371 42.3804 53.7147C41.9036 53.7147 41.5157 54.1032 41.5157 54.5808C41.5157 55.0584 41.9036 55.4469 42.3804 55.4469V56.3129H41.5157C41.5157 55.8354 41.1279 55.4469 40.6511 55.4469C40.1743 55.4469 39.7865 55.8354 39.7865 56.3129C39.3097 56.3129 38.9219 56.7014 38.9219 57.179C38.9219 57.6566 39.3097 58.0451 39.7865 58.0451C39.7865 58.5227 40.1743 58.9112 40.6511 58.9112C41.1279 58.9112 41.5157 58.5227 41.5157 58.0451H42.3804V60.5792C41.9036 60.5792 41.5157 60.9677 41.5157 61.4453C41.5157 61.9229 41.9036 62.3113 42.3804 62.3113C42.3804 62.3113 42.3839 62.3113 42.3875 62.3113C42.3875 62.3327 42.3804 62.3541 42.3804 62.3755C42.3804 62.8531 42.7682 63.2416 43.245 63.2416C43.7218 63.2416 44.1096 62.8531 44.1096 62.3755C44.1096 62.3541 44.106 62.3327 44.1025 62.3113C44.1025 62.3113 44.106 62.3113 44.1096 62.3113C44.5864 62.3113 44.9742 61.9229 44.9742 61.4453C44.9742 60.9677 44.5864 60.5792 44.1096 60.5792V58.0451H44.9742C44.9742 58.5227 45.3621 58.9112 45.8388 58.9112C46.3156 58.9112 46.7035 58.5227 46.7035 58.0451Z" />
            <path d="M35.4593 28.5874C35.4593 28.1098 35.0715 27.7213 34.5947 27.7213C34.1179 27.7213 33.7301 28.1098 33.7301 28.5874H32.8655V27.7213C33.3422 27.7213 33.7301 27.3328 33.7301 26.8552C33.7301 26.3776 33.3422 25.9891 32.8655 25.9891C32.8655 25.5115 32.4776 25.123 32.0008 25.123C31.524 25.123 31.1362 25.5115 31.1362 25.9891C30.6594 25.9891 30.2716 26.3776 30.2716 26.8552C30.2716 27.3328 30.6594 27.7213 31.1362 27.7213V28.5874H30.2716C30.2716 28.1098 29.8838 27.7213 29.407 27.7213C28.9302 27.7213 28.5424 28.1098 28.5424 28.5874C28.0656 28.5874 27.6777 28.9758 27.6777 29.4534C27.6777 29.931 28.0656 30.3195 28.5424 30.3195C28.5424 30.7971 28.9302 31.1856 29.407 31.1856C29.8838 31.1856 30.2716 30.7971 30.2716 30.3195H31.1362V32.8536C30.6594 32.8536 30.2716 33.2421 30.2716 33.7197C30.2716 34.1973 30.6594 34.5858 31.1362 34.5858C31.1362 34.5858 31.1398 34.5858 31.1433 34.5858C31.1433 34.6071 31.1362 34.6285 31.1362 34.6499C31.1362 35.1275 31.524 35.516 32.0008 35.516C32.4776 35.516 32.8655 35.1275 32.8655 34.6499C32.8655 34.6285 32.8619 34.6071 32.8583 34.5858C32.8583 34.5858 32.8619 34.5858 32.8655 34.5858C33.3422 34.5858 33.7301 34.1973 33.7301 33.7197C33.7301 33.2421 33.3422 32.8536 32.8655 32.8536V30.3195H33.7301C33.7301 30.7971 34.1179 31.1856 34.5947 31.1856C35.0715 31.1856 35.4593 30.7971 35.4593 30.3195C35.9361 30.3195 36.3239 29.931 36.3239 29.4534C36.3239 28.9758 35.9361 28.5874 35.4593 28.5874Z" />
            <path d="M12.9724 8.66047C12.9724 8.18288 12.5846 7.79439 12.1078 7.79439C12.1078 7.3168 11.7199 6.92831 11.2431 6.92831C10.7664 6.92831 10.3785 7.3168 10.3785 7.79439H9.5139V6.92831C9.99069 6.92831 10.3785 6.53983 10.3785 6.06224C10.3785 5.58464 9.99069 5.19616 9.5139 5.19616C9.5139 4.71857 9.12607 4.33008 8.64928 4.33008C8.17249 4.33008 7.78466 4.71857 7.78466 5.19616C7.30787 5.19616 6.92004 5.58464 6.92004 6.06224C6.92004 6.53983 7.30787 6.92831 7.78466 6.92831V7.79439H6.92004C6.92004 7.3168 6.5322 6.92831 6.05541 6.92831C5.57863 6.92831 5.1908 7.3168 5.1908 7.79439C4.71401 7.79439 4.32617 8.18288 4.32617 8.66047C4.32617 9.13806 4.71401 9.52655 5.1908 9.52655C5.1908 10.0041 5.57863 10.3926 6.05541 10.3926C6.5322 10.3926 6.92004 10.0041 6.92004 9.52655H7.78466V12.0606C7.30787 12.0606 6.92004 12.4491 6.92004 12.9267C6.92004 13.4043 7.30787 13.7928 7.78466 13.7928C7.78466 13.7928 7.78822 13.7928 7.79177 13.7928C7.79177 13.8142 7.78466 13.8356 7.78466 13.8569C7.78466 14.3345 8.17249 14.723 8.64928 14.723C9.12607 14.723 9.5139 14.3345 9.5139 13.8569C9.5139 13.8356 9.51035 13.8142 9.50679 13.7928C9.50679 13.7928 9.51034 13.7928 9.5139 13.7928C9.99069 13.7928 10.3785 13.4043 10.3785 12.9267C10.3785 12.4491 9.99069 12.0606 9.5139 12.0606V9.52655H10.3785C10.3785 10.0041 10.7664 10.3926 11.2431 10.3926C11.7199 10.3926 12.1078 10.0041 12.1078 9.52655C12.5846 9.52655 12.9724 9.13806 12.9724 8.66047Z" />
            <path d="M27.6761 7.79439C27.6761 7.3168 27.2883 6.92831 26.8115 6.92831C26.3347 6.92831 25.9469 7.3168 25.9469 7.79439H25.0823V6.92831C25.559 6.92831 25.9469 6.53983 25.9469 6.06224C25.9469 5.58464 25.559 5.19616 25.0823 5.19616C25.0823 4.71857 24.6944 4.33008 24.2176 4.33008C23.7409 4.33008 23.353 4.71857 23.353 5.19616C22.8762 5.19616 22.4884 5.58464 22.4884 6.06224C22.4884 6.53983 22.8762 6.92831 23.353 6.92831V7.79439H22.4884C22.4884 7.3168 22.1006 6.92831 21.6238 6.92831C21.147 6.92831 20.7592 7.3168 20.7592 7.79439C20.2824 7.79439 19.8945 8.18288 19.8945 8.66047C19.8945 9.13806 20.2824 9.52655 20.7592 9.52655C20.7592 10.0041 21.147 10.3926 21.6238 10.3926C22.1006 10.3926 22.4884 10.0041 22.4884 9.52655H23.353V12.0606C22.8762 12.0606 22.4884 12.4491 22.4884 12.9267C22.4884 13.4043 22.8762 13.7928 23.353 13.7928C23.353 13.7928 23.3566 13.7928 23.3601 13.7928C23.3601 13.8142 23.353 13.8356 23.353 13.8569C23.353 14.3345 23.7409 14.723 24.2176 14.723C24.6944 14.723 25.0823 14.3345 25.0823 13.8569C25.0823 13.8356 25.0787 13.8142 25.0751 13.7928C25.0751 13.7928 25.0787 13.7928 25.0823 13.7928C25.559 13.7928 25.9469 13.4043 25.9469 12.9267C25.9469 12.4491 25.559 12.0606 25.0823 12.0606V9.52655H25.9469C25.9469 10.0041 26.3347 10.3926 26.8115 10.3926C27.2883 10.3926 27.6761 10.0041 27.6761 9.52655C28.1529 9.52655 28.5407 9.13806 28.5407 8.66047C28.5407 8.18288 28.1529 7.79439 27.6761 7.79439Z" />
            <path d="M19.889 16.4585C19.889 15.9809 19.5012 15.5924 19.0244 15.5924C18.5476 15.5924 18.1598 15.9809 18.1598 16.4585H17.2951V15.5924C17.7719 15.5924 18.1598 15.2039 18.1598 14.7263C18.1598 14.2487 17.7719 13.8602 17.2951 13.8602C17.2951 13.3826 16.9073 12.9941 16.4305 12.9941C15.9537 12.9941 15.5659 13.3826 15.5659 13.8602C15.0891 13.8602 14.7013 14.2487 14.7013 14.7263C14.7013 15.2039 15.0891 15.5924 15.5659 15.5924V16.4585H14.7013C14.7013 15.9809 14.3135 15.5924 13.8367 15.5924C13.3599 15.5924 12.972 15.9809 12.972 16.4585C12.4953 16.4585 12.1074 16.8469 12.1074 17.3245C12.1074 17.8021 12.4953 18.1906 12.972 18.1906C12.972 18.6682 13.3599 19.0567 13.8367 19.0567C14.3135 19.0567 14.7013 18.6682 14.7013 18.1906H15.5659V20.7247C15.0891 20.7247 14.7013 21.1132 14.7013 21.5908C14.7013 22.0684 15.0891 22.4568 15.5659 22.4568C15.5659 22.4568 15.5695 22.4568 15.573 22.4568C15.573 22.4782 15.5659 22.4996 15.5659 22.521C15.5659 22.9986 15.9537 23.3871 16.4305 23.3871C16.9073 23.3871 17.2951 22.9986 17.2951 22.521C17.2951 22.4996 17.2916 22.4782 17.288 22.4568C17.288 22.4568 17.2916 22.4568 17.2951 22.4568C17.7719 22.4568 18.1598 22.0684 18.1598 21.5908C18.1598 21.1132 17.7719 20.7247 17.2951 20.7247V18.1906H18.1598C18.1598 18.6682 18.5476 19.0567 19.0244 19.0567C19.5012 19.0567 19.889 18.6682 19.889 18.1906C20.3658 18.1906 20.7536 17.8021 20.7536 17.3245C20.7536 16.8469 20.3658 16.4585 19.889 16.4585Z" />
            <path d="M44.1072 8.66047C44.1072 8.18288 43.7193 7.79439 43.2425 7.79439C43.2425 7.3168 42.8547 6.92831 42.3779 6.92831C41.9011 6.92831 41.5133 7.3168 41.5133 7.79439H40.6487V6.92831C41.1255 6.92831 41.5133 6.53983 41.5133 6.06224C41.5133 5.58464 41.1255 5.19616 40.6487 5.19616C40.6487 4.71857 40.2608 4.33008 39.784 4.33008C39.3073 4.33008 38.9194 4.71857 38.9194 5.19616C38.4426 5.19616 38.0548 5.58464 38.0548 6.06224C38.0548 6.53983 38.4426 6.92831 38.9194 6.92831V7.79439H38.0548C38.0548 7.3168 37.667 6.92831 37.1902 6.92831C36.7134 6.92831 36.3256 7.3168 36.3256 7.79439C35.8488 7.79439 35.4609 8.18288 35.4609 8.66047C35.4609 9.13806 35.8488 9.52655 36.3256 9.52655C36.3256 10.0041 36.7134 10.3926 37.1902 10.3926C37.667 10.3926 38.0548 10.0041 38.0548 9.52655H38.9194V12.0606C38.4426 12.0606 38.0548 12.4491 38.0548 12.9267C38.0548 13.4043 38.4426 13.7928 38.9194 13.7928C38.9194 13.7928 38.923 13.7928 38.9265 13.7928C38.9265 13.8142 38.9194 13.8356 38.9194 13.8569C38.9194 14.3345 39.3073 14.723 39.784 14.723C40.2608 14.723 40.6487 14.3345 40.6487 13.8569C40.6487 13.8356 40.6451 13.8142 40.6416 13.7928C40.6416 13.7928 40.6451 13.7928 40.6487 13.7928C41.1255 13.7928 41.5133 13.4043 41.5133 12.9267C41.5133 12.4491 41.1255 12.0606 40.6487 12.0606V9.52655H41.5133C41.5133 10.0041 41.9011 10.3926 42.3779 10.3926C42.8547 10.3926 43.2425 10.0041 43.2425 9.52655C43.7193 9.52655 44.1072 9.13806 44.1072 8.66047Z" />
            <path d="M58.8128 7.79439C58.8128 7.3168 58.425 6.92831 57.9482 6.92831C57.4714 6.92831 57.0836 7.3168 57.0836 7.79439H56.219V6.92831C56.6958 6.92831 57.0836 6.53983 57.0836 6.06224C57.0836 5.58464 56.6958 5.19616 56.219 5.19616C56.219 4.71857 55.8311 4.33008 55.3544 4.33008C54.8776 4.33008 54.4897 4.71857 54.4897 5.19616C54.013 5.19616 53.6251 5.58464 53.6251 6.06224C53.6251 6.53983 54.013 6.92831 54.4897 6.92831V7.79439H53.6251C53.6251 7.3168 53.2373 6.92831 52.7605 6.92831C52.2837 6.92831 51.8959 7.3168 51.8959 7.79439C51.4191 7.79439 51.0312 8.18288 51.0312 8.66047C51.0312 9.13806 51.4191 9.52655 51.8959 9.52655C51.8959 10.0041 52.2837 10.3926 52.7605 10.3926C53.2373 10.3926 53.6251 10.0041 53.6251 9.52655H54.4897V12.0606C54.013 12.0606 53.6251 12.4491 53.6251 12.9267C53.6251 13.4043 54.013 13.7928 54.4897 13.7928C54.4897 13.7928 54.4933 13.7928 54.4969 13.7928C54.4969 13.8142 54.4897 13.8356 54.4897 13.8569C54.4897 14.3345 54.8776 14.723 55.3544 14.723C55.8311 14.723 56.219 14.3345 56.219 13.8569C56.219 13.8356 56.2154 13.8142 56.2119 13.7928C56.2119 13.7928 56.2154 13.7928 56.219 13.7928C56.6958 13.7928 57.0836 13.4043 57.0836 12.9267C57.0836 12.4491 56.6958 12.0606 56.219 12.0606V9.52655H57.0836C57.0836 10.0041 57.4714 10.3926 57.9482 10.3926C58.425 10.3926 58.8128 10.0041 58.8128 9.52655C59.2896 9.52655 59.6775 9.13806 59.6775 8.66047C59.6775 8.18288 59.2896 7.79439 58.8128 7.79439Z" />
            <path d="M51.0257 16.4585C51.0257 15.9809 50.6379 15.5924 50.1611 15.5924C49.6843 15.5924 49.2965 15.9809 49.2965 16.4585H48.4319V15.5924C48.9086 15.5924 49.2965 15.2039 49.2965 14.7263C49.2965 14.2487 48.9086 13.8602 48.4319 13.8602C48.4319 13.3826 48.044 12.9941 47.5672 12.9941C47.0905 12.9941 46.7026 13.3826 46.7026 13.8602C46.2258 13.8602 45.838 14.2487 45.838 14.7263C45.838 15.2039 46.2258 15.5924 46.7026 15.5924V16.4585H45.838C45.838 15.9809 45.4502 15.5924 44.9734 15.5924C44.4966 15.5924 44.1088 15.9809 44.1088 16.4585C43.632 16.4585 43.2441 16.8469 43.2441 17.3245C43.2441 17.8021 43.632 18.1906 44.1088 18.1906C44.1088 18.6682 44.4966 19.0567 44.9734 19.0567C45.4502 19.0567 45.838 18.6682 45.838 18.1906H46.7026V20.7247C46.2258 20.7247 45.838 21.1132 45.838 21.5908C45.838 22.0684 46.2258 22.4568 46.7026 22.4568C46.7026 22.4568 46.7062 22.4568 46.7097 22.4568C46.7097 22.4782 46.7026 22.4996 46.7026 22.521C46.7026 22.9986 47.0905 23.3871 47.5672 23.3871C48.044 23.3871 48.4319 22.9986 48.4319 22.521C48.4319 22.4996 48.4283 22.4782 48.4247 22.4568C48.4247 22.4568 48.4283 22.4568 48.4319 22.4568C48.9086 22.4568 49.2965 22.0684 49.2965 21.5908C49.2965 21.1132 48.9086 20.7247 48.4319 20.7247V18.1906H49.2965C49.2965 18.6682 49.6843 19.0567 50.1611 19.0567C50.6379 19.0567 51.0257 18.6682 51.0257 18.1906C51.5025 18.1906 51.8903 17.8021 51.8903 17.3245C51.8903 16.8469 51.5025 16.4585 51.0257 16.4585Z" />
            <path d="M19.0257 38.9838C19.0257 38.5063 18.6379 38.1178 18.1611 38.1178C17.6843 38.1178 17.2965 38.5063 17.2965 38.9838H16.4319V38.1178C16.9087 38.1178 17.2965 37.7293 17.2965 37.2517C17.2965 36.7741 16.9087 36.3856 16.4319 36.3856C16.4319 35.908 16.044 35.5195 15.5673 35.5195C15.0905 35.5195 14.7026 35.908 14.7026 36.3856C14.2258 36.3856 13.838 36.7741 13.838 37.2517C13.838 37.7293 14.2258 38.1178 14.7026 38.1178V38.9838H13.838C13.838 38.5063 13.4502 38.1178 12.9734 38.1178C12.4966 38.1178 12.1088 38.5063 12.1088 38.9838C11.632 38.9838 11.2441 39.3723 11.2441 39.8499C11.2441 40.3275 11.632 40.716 12.1088 40.716C12.1088 41.1936 12.4966 41.5821 12.9734 41.5821C13.4502 41.5821 13.838 41.1936 13.838 40.716H14.7026V43.2501C14.2258 43.2501 13.838 43.6386 13.838 44.1162C13.838 44.5938 14.2258 44.9822 14.7026 44.9822C14.7026 44.9822 14.7062 44.9822 14.7097 44.9822C14.7097 45.0036 14.7026 45.025 14.7026 45.0464C14.7026 45.524 15.0905 45.9125 15.5673 45.9125C16.044 45.9125 16.4319 45.524 16.4319 45.0464C16.4319 45.025 16.4283 45.0036 16.4248 44.9822C16.4248 44.9822 16.4283 44.9822 16.4319 44.9822C16.9087 44.9822 17.2965 44.5938 17.2965 44.1162C17.2965 43.6386 16.9087 43.2501 16.4319 43.2501V40.716H17.2965C17.2965 41.1936 17.6843 41.5821 18.1611 41.5821C18.6379 41.5821 19.0257 41.1936 19.0257 40.716C19.5025 40.716 19.8904 40.3275 19.8904 39.8499C19.8904 39.3723 19.5025 38.9838 19.0257 38.9838Z" />
            <path d="M51.891 38.9838C51.891 38.5063 51.5031 38.1178 51.0263 38.1178C50.5496 38.1178 50.1617 38.5063 50.1617 38.9838H49.2971V38.1178C49.7739 38.1178 50.1617 37.7293 50.1617 37.2517C50.1617 36.7741 49.7739 36.3856 49.2971 36.3856C49.2971 35.908 48.9093 35.5195 48.4325 35.5195C47.9557 35.5195 47.5679 35.908 47.5679 36.3856C47.0911 36.3856 46.7032 36.7741 46.7032 37.2517C46.7032 37.7293 47.0911 38.1178 47.5679 38.1178V38.9838H46.7032C46.7032 38.5063 46.3154 38.1178 45.8386 38.1178C45.3618 38.1178 44.974 38.5063 44.974 38.9838C44.4972 38.9838 44.1094 39.3723 44.1094 39.8499C44.1094 40.3275 44.4972 40.716 44.974 40.716C44.974 41.1936 45.3618 41.5821 45.8386 41.5821C46.3154 41.5821 46.7032 41.1936 46.7032 40.716H47.5679V43.2501C47.0911 43.2501 46.7032 43.6386 46.7032 44.1162C46.7032 44.5938 47.0911 44.9822 47.5679 44.9822C47.5679 44.9822 47.5714 44.9822 47.575 44.9822C47.575 45.0036 47.5679 45.025 47.5679 45.0464C47.5679 45.524 47.9557 45.9125 48.4325 45.9125C48.9093 45.9125 49.2971 45.524 49.2971 45.0464C49.2971 45.025 49.2935 45.0036 49.29 44.9822C49.29 44.9822 49.2935 44.9822 49.2971 44.9822C49.7739 44.9822 50.1617 44.5938 50.1617 44.1162C50.1617 43.6386 49.7739 43.2501 49.2971 43.2501V40.716H50.1617C50.1617 41.1936 50.5496 41.5821 51.0263 41.5821C51.5031 41.5821 51.891 41.1936 51.891 40.716C52.3677 40.716 52.7556 40.3275 52.7556 39.8499C52.7556 39.3723 52.3677 38.9838 51.891 38.9838Z" />
        </svg>
    );
};

export default LogoIcon;
