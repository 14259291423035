import { getButtonMapping, removeTypenameProp } from "../../utils";
import { getMedia } from "../Image/imageMapping";

export const ctaCardLinksMapping = (data: any) => {
    if (!data) return null;
    let cleanedQuery = removeTypenameProp(data);
    const {
        title = "",
        cards: { cardRepeater = [] },
    } = cleanedQuery;

    const cardsData = cardRepeater.map((card) => {
        const {
            title,
            includeButton,
            buttonGroup,
            link,
            links,
            backgroundGroup: {
                backgroundColour: { backgroundColour },
                backgroundType,
                image,
            },
            showLogo,
        } = card;

        return {
            title,
            includeButton,
            background: {
                backgroundType,
                backgroundColour,
                image: getMedia(image),
            },
            link,
            links: getButtonMapping(links) || [],
            buttons: card.includeButton ? getButtonMapping(buttonGroup) : [],
            showLogo,
        };
    });

    const mappedData = {
        title,
        isCardLinks: true,
        cards: cardsData,
    };

    return mappedData;
};
