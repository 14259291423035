/** @format */

import {OptionListProps} from '../patterns/FormikField/OptionProps';

export const NewsletterFormData = {
	label: 'Subscribe to Newsletter',
	paragraphs: [
		'Subscribe, get a free copy of our brochures and keep up to date with our latest news and events.',
		'Thank you for considering our institution for your higher education. We appreciate your interest in our programs and the potential opportunity to assist you in your academic journey. To help us provide the most accurate information catered to your needs, please take a moment to fill out this enquiry form. It will ask for basic contact information, your preferred program of study, previous academic background, and any specific questions or details you would like us to address.',
		'Please rest assured, the information collected is solely for the purpose of serving your academic interests and will be handled with utmost confidentiality. We are eager to learn more about you and your educational goals. Once completed, our dedicated admissions team will review your enquiry and respond as quickly as possible.',
	],
};

export const enquiryTypeOptions: OptionListProps = [
	{value: '', text: 'Select your option', disabled: true},
	{value: 'membership', text: 'Membership'},
	{value: 'events', text: 'Events'},
	{value: 'sponsorship', text: 'Sponsorship'},
	{value: 'other', text: 'Other...'},
];
