import { removeTypenameProp } from "../../utils";
import { getMedia } from "../Image/imageMapping";
import DOMPurify from "isomorphic-dompurify";

export default (data: any) => {
    if (!data) return null;
    const cleanedQuery = removeTypenameProp(data);
    const { title = "", gridList } = cleanedQuery;

    const gridMappingData = {
        title,
        gridList: gridList.map((gridItem) => {
            const { title = "", wysiwyg = "", image } = gridItem;
            return {
                title,
                html: { __html: DOMPurify.sanitize(wysiwyg) },
                image: getMedia(image),
            };
        }),
    };

    return gridMappingData;
};
