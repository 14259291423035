/** @format */

const roomCarouselMapping = (data: any) => {
	const {showSectionHeading, sectionHeading, roomRepeater = []} = data;

	return {
		showSectionHeading,
		sectionHeading,
		roomRepeater:
			roomRepeater?.map((room: any) => {
				const {tabLabel, title, subtitle, description, image} = room;

				return {
					tabLabel,
					title,
					subtitle,
					description,
					image: {
						sourceUrl: image.sourceUrl,
						altText: image.altText,
						mediaDetails: {
							height: image.mediaDetails.height,
							width: image.mediaDetails.width,
						},
					},
				};
			}) || [],
	};
};

export default roomCarouselMapping;
