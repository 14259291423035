/** @format */

import {
	Box,
	Button,
	Container,
	Flex,
	Grid,
	Heading,
	HStack,
	LinkBox,
	LinkOverlay,
	Text,
	useBreakpointValue,
	useBreakpoint,
} from '@chakra-ui/react';
import Image from 'next/image';
import React, {useState} from 'react';
import {ArrowRight, File} from 'react-feather';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import {getDomainFontFamily, getMostFrequent} from '../../utils';
import LogoIcon from '../LogoIcon';
import {decode} from 'html-entities';

interface LinkProps {
	url: string;
	target?: string;
}

interface CtaCardsProps {
	title: string;
	cards: CtaCardProps[];
	subdomain: string;
	link?: LinkProps;
	isCardLinks?: boolean;
}

interface CtaCardProps {
	title: string;
	cardHeight?: string;
	background: {
		backgroundType: string;
		backgroundColour: string;
		image: {
			src: string;
			alt: string;
		};
	};
	includeButton?: boolean;
	buttons?: {
		style: string;
		url: string;
		target?: string;
		colorScheme?: string;
		title: string;
	}[];
	link?: LinkProps;
	links?: [LinkProps];
	index?: number;
	hoveredCardIndex?: number;
	setHoveredCardIndex?: React.Dispatch<React.SetStateAction<number | null>>;
	isCardLinks?: boolean;
	showLogo?: boolean;
	rounded: string;
	textStylesSelected?: any;
	subdomain: string;
}

const getCardTheme = (background, buttons) => {
	const {backgroundColour = 'aqua.500', backgroundType} = background;

	const cardTheme = {
		'maroon.500': {
			cardHoverBg: colors.maroon[600],
			color: colors.pink[500],
		},
		'pink.500': {
			cardHoverBg: colors.pink[600],
			color: colors.maroon[500],
		},
		'pine.500': {
			cardHoverBg: colors.pine[600],
			color: colors.pink[500],
		},
		'pine.700': {
			cardHoverBg: colors.pine[800],
			color: colors.pink[500],
		},
		'aqua.500': {
			cardHoverBg: colors.aqua[600],
			color: colors.greyWarm[500],
		},
		'aqua.50': {
			cardHoverBg: colors.aqua[100],
			color: colors.aqua[500],
		},
	};

	if (backgroundType === 'image' && buttons) {
		const buttonColor = getMostFrequent(
			buttons.map(button => button.colorScheme),
		);

		if (buttonColor)
			return {
				color: colors[buttonColor][500],
			};

		return {color: colors.white.whiteAlpha};
	}

	const isBackgroundColourInTheme =
		backgroundColour && cardTheme[backgroundColour];
	if (isBackgroundColourInTheme) {
		return cardTheme[backgroundColour];
	}

	return cardTheme['aqua.500'];
};

const CtaCards: React.FC<CtaCardsProps> = ({
	title,
	cards,
	subdomain,
	isCardLinks,
}) => {
	const cardHeight = '22.5rem';
	const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);
	const rounded = isCardLinks ? '2xl' : '';

	let textStylesSelected = {
		...useBreakpointValue(
			{base: textStyles.text3Xl, md: textStyles.text5Xl},
			{ssr: true},
		),
	};
	if (isCardLinks) textStylesSelected = {...textStyles.text3Xl};

	return (
		<Box
			as='section'
			overflow='hidden'
			position='relative'
			py={{base: 12, md: 16}}>
			<Container position='relative' maxW='container.xl' mx='auto'>
				{title ? (
					<Text mb={'3.5rem'} {...textStyles.textxl}>
						{decode(title)}
					</Text>
				) : null}

				<Grid
					templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}}
					gap={{base: 6, md: 8, lg: 14}}>
					{cards.map((card, i) => (
						<CtaCard
							key={`${i}-card`}
							index={i}
							cardHeight={cardHeight}
							setHoveredCardIndex={setHoveredCardIndex}
							hoveredCardIndex={hoveredCardIndex}
							isCardLinks={isCardLinks}
							rounded={rounded}
							textStylesSelected={textStylesSelected}
							subdomain={subdomain}
							{...card}
						/>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

const CtaCard: React.FC<CtaCardProps> = ({
	title,
	links,
	cardHeight,
	background,
	includeButton,
	link,
	buttons,
	hoveredCardIndex,
	setHoveredCardIndex,
	index,
	isCardLinks,
	rounded,
	textStylesSelected,
	showLogo,
	subdomain,
}) => {
	const breakpoint = useBreakpoint();
	const colorScheme = background?.backgroundColour?.split('.')[0];
	// if card background color is defined
	const defaultCardStyle = getCardTheme(background, buttons);
	let bg =
		background.backgroundType === 'image' ? '' : background.backgroundColour;
	if (hoveredCardIndex === index) bg = defaultCardStyle.cardHoverBg;

	return (
		<LinkBox
			minW={{base: '100%', sm: 'auto'}}
			position='relative'
			h={cardHeight ? cardHeight : 'auto'}
			bg={bg}
			onMouseEnter={() => setHoveredCardIndex(index)}
			onMouseLeave={() => setHoveredCardIndex(null)}
			rounded={rounded}>
			{background.backgroundType === 'image' ? (
				<Box>
					<Box
						boxShadow={hoveredCardIndex === index ? '2xl' : null}
						className='image-box'
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							overflow: 'hidden',
						}}>
						<Image
							src={background.image.src}
							alt={background.image.alt}
							fill
							style={{
								objectFit: 'cover',
								transition: 'transform 0.5s ease-in-out',
								transform:
									hoveredCardIndex === index ? 'scale(1.10)' : 'scale(1)', // Zoom effect on hover
							}}
						/>
					</Box>
					<Box
						bg={{base: 'rgba(26, 26, 26, 0.36)'}}
						className='image-box-overlay'
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
						}}
					/>
				</Box>
			) : null}

			<Flex
				position='relative'
				display='flex'
				flexDirection='column'
				justifyContent={isCardLinks ? 'flex-start' : 'space-between'}
				flex='column'
				py={isCardLinks ? {base: '2rem'} : {base: '2rem', md: '3rem'}}
				px={{base: '2rem', md: '2rem'}}
				h={cardHeight ? cardHeight : 'auto'}
				zIndex={{base: 3}}>
				<HStack
					className='h-stack'
					justifyContent={'space-between'}
					mb={'4rem'}>
					<Heading
						noOfLines={{base: 3, md: 2}}
						{...textStylesSelected}
						fontFamily={getDomainFontFamily(subdomain)}
						color={{base: defaultCardStyle.color}}>
						{decode(title)}
					</Heading>
					{isCardLinks && showLogo && breakpoint !== 'base' && (
						<>
							{/* <Image
                                src="/images/LogoShield.svg"
                                alt="logo_alt_here"
                                width={100}
                                height={100}
                                style={{
                                    objectFit: "none",
                                    width: "auto",
                                    height: "auto",
                                }}
                            /> */}
							<LogoIcon fill={defaultCardStyle.color} />
						</>
					)}
				</HStack>
				{isCardLinks ? (
					<CTACardBodyButtonVertical
						links={links}
						colorScheme={colorScheme}
						defaultCardStyle={defaultCardStyle}
					/>
				) : (
					<CTACardBody
						includeButton={includeButton}
						buttons={buttons}
						link={link}
					/>
				)}
			</Flex>
		</LinkBox>
	);
};

const CTACardBody = ({includeButton, buttons, link}) => {
	if (includeButton)
		return (
			<Box>
				{buttons?.map((button, i) => (
					<Box key={`${i}-text-button`}>
						<LinkOverlay href={button?.url} target={button?.target} />
						<Button
							variant={button.style}
							size='lg'
							as='a'
							href={button?.url}
							target={button?.target}
							colorScheme={button?.colorScheme || 'aqua'}>
							{button?.title}
						</Button>
					</Box>
				))}
			</Box>
		);

	return <LinkOverlay href={link?.url} target={link?.target} />;
};

const CTACardBodyButtonVertical = ({
	links,
	colorScheme: c,
	defaultCardStyle,
}) => {
	return (
		<Box>
			{links?.map((link, i) => (
				<Box key={`${i}-text-link`}>
					<Button
						// color={
						//     c === "pink" || c === "greyWarm" ? "black" : "white"
						// }
						color={defaultCardStyle.color}
						// color={`${c}.200`}
						// colorScheme={`${c}` || "aqua.500"}
						size='sm'
						as='a'
						href={link?.url}
						target={link?.target}
						variant='link'
						leftIcon={link?.url.includes('.pdf') ? <File /> : <ArrowRight />}>
						{link?.title}
					</Button>
				</Box>
			))}
		</Box>
	);
};

export default CtaCards;
