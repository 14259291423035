import {
    Box,
    Container,
    Grid,
    GridItem,
    Heading,
    Text,
} from "@chakra-ui/react";
import Image from "next/image";
import { colors } from "../../styles/js/colors";
import { textStyles } from "../../styles/js/textStyles";

const TestimonialWithImage = ({ ...props }) => {
    const {
        heading = "",
        testimonial,
        author,
        citation,
        image,
        semester,
    } = props;
    return (
        <Box
            as="section"
            overflow="hidden"
            position="relative"
            my={"2rem"}
            bg={colors.grey[50]}
        >
            <Container
                maxW={{ base: "container.xl", md: "container.xl" }}
                mx="auto"
            >
                <Grid
                    py={"4rem"}
                    templateColumns={{
                        base: "1fr",
                        md: "repeat(12, 1fr)",
                    }}
                    gap={{ base: "4rem 0", md: "0 2.5rem", xl: "0 3.5rem" }}
                >
                    <GridItem
                        gridColumn={{ base: "1", md: "1 / 7", xl: "2 / 7" }}
                        // colSpan={{ base: 12, md: 6: xl: 5}}
                        alignSelf={{ base: 'start', lg: 'center'}}
                    >
                        {heading ? (
                            <Heading
                                noOfLines={[1, 1, 2]}
                                {...textStyles.displayxl}
                                color={{ base: colors.aqua["500"] }}
                                mb={{ base: "2rem" }}
                            >
                                {heading}
                            </Heading>
                        ) : null}
                        {testimonial ? (
                            <Text {...textStyles.textmd} mb={{ base: "2rem" }}>
                                {testimonial}
                            </Text>
                        ) : null}
                        {author ? (
                            <Text {...textStyles.headingsm}>
                                {author}
                            </Text>
                        ) : null}
                        {semester ? (
                            <Text {...textStyles.textsm}>{semester}</Text>
                        ) : null}
                    </GridItem>
                    <GridItem
                        gridColumn={{ base: "1", md: "7 / 13", xl: "8 / 13" }}
                        // colSpan={{ base: 12, md: 6: xl: 5}}
                    >
                        <Box
                            w={"100%"}
                            h={"100%"}
                            overflow={"hidden"}
                            position="relative"
                            minHeight={"25rem"}
                        >
                            <Image
                                src={image.src}
                                alt={author}
                                className="testimonial-image"
                                fill={true}
                                style={{
                                    objectFit: "contain",
                                    // objectFit: "cover",
                                    // objectFit: "none",
                                    // objectFit: "scale-down",
                                    // objectFit: "fill",
                                }}
                            />
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );
};

export default TestimonialWithImage;
