/** @format */

import {getButtonMapping, removeTypenameProp} from '../../utils';
import {getMedia} from '../Image/imageMapping';

export const ctaCardsMapping = (data: any) => {
	if (!data) return null;
	let cleanedQuery = removeTypenameProp(data);

	const {
		title = '',
		cards: {cardRepeater = []},
	} = cleanedQuery;

	const cardsData = cardRepeater.map(card => {
		const {
			title = '',
			includeButton,
			buttonGroup,
			link,
			backgroundGroup: {
				backgroundColour: {backgroundColour},
				backgroundType,
				image,
			},
		} = card;

		return {
			title,
			includeButton,
			background: {
				backgroundType,
				backgroundColour,
				image: getMedia(image),
			},
			link,
			buttons: card.includeButton ? getButtonMapping(buttonGroup) : [],
		};
	});

	const mappedData = {
		title,
		cards: cardsData,
	};

	return mappedData;
};
