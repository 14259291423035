const postGridMapping = (data: any) => {
    return {
        id: 'id',
        title: 'title',
        description: 'description',
        image: 'image',
    }
}

export default postGridMapping;

