/** @format */

import {Box, Button, Heading} from '@chakra-ui/react';
import React from 'react';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import ModalNewsLetter from '../ModalNewsLetter';
import LeftAlignedCTABanner from './LeftAlignedCTABanner';
import {decode} from 'html-entities';

const CTABanner = (props: any) => {
	switch (props.variant) {
		default:
			return <LeftAlignedCTABanner {...props} />;
	}
};

export default CTABanner;

export const Ctas = ({ctas, isOpen, onOpen, onClose, subdomain}) => {
	return ctas.map((cta, i) => (
		<Box key={`cta-${i}`} width='100%'>
			{cta.opensForm ? (
				<>
					<CTAButtonGroupComponent
						buttons={cta.buttons}
						onClick={() => (cta.opensForm ? onOpen() : null)}
					/>
					<ModalNewsLetter onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
				</>
			) : (
				<CTAButtonGroupComponent
					buttons={cta.buttons}
					onClick={null} // Pass null when there's no onClick.
				/>
			)}
		</Box>
	));
};

export const CTAButtonGroupComponent = ({buttons, onClick}) => {
	if (!buttons || !buttons.length) return null;
	return buttons.map((button, j) => (
		<Button
			width='100%'
			key={`${j}-text-button`}
			size='lg'
			variant={button.style}
			target={onClick ? '_self' : button.target}
			as={onClick ? undefined : 'a'} // If onClick is present, don't use 'a'.
			href={onClick ? undefined : button.url} // If onClick is present, don't use href.
			justifyContent='center'
			flexShrink={0}
			colorScheme={button.colorScheme || ''}
			onClick={onClick}>
			{button.title}
		</Button>
	));
};

export const CTAHeading = ({
	title,
	domainFontFamily,
	headingStylesSelected,
}) => {
	if (!title) return null;

	return (
		<Heading
			className='cta-heading'
			{...headingStylesSelected}
			noOfLines={{base: 3, md: 2}}
			mb={{base: '1.5rem', md: '2rem'}}
			whiteSpace='pre-line'
			color={{base: colors.white.whiteAlpha}}
			fontFamily={domainFontFamily}>
			{decode(title)}
		</Heading>
	);
};

export const CTASubtitle = ({text, domainFontFamily}) => {
	if (!text) return null;

	return (
		<Box
			{...textStyles.textsm}
			whiteSpace='pre-line'
			mb={{base: '2'}}
			color={{base: colors.white.whiteAlpha}}
			fontFamily={domainFontFamily}>
			{text}
		</Box>
	);
};
