import DOMPurify from "isomorphic-dompurify";
import { getButtonMapping, removeTypenameProp } from "../../utils";
import { getMedia } from "../Image/imageMapping";

export const testimonialWithImageMapping = (data: any) => {
    if (!data) return "";
    let cleanedQuery = removeTypenameProp(data);
    const {
        heading,
        testimonial,
        author,
        semester,
        citation = false,
        image,
    } = cleanedQuery;

    return {
        heading,
        testimonial,
        author,
        semester,
        citation,
        image: getMedia(image),
    };
};
