import React from "react";

const LogoIconGregory = ({ fill = "" }) => {
    return (
        <svg
            width="64"
            height="80"
            viewBox="0 0 38 48"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg">
            <g id="ca1d2219-373f-4cef-aec7-198b87d89acd_10">
                <path id="Vector" d="M0.000123214 0V27.7174C-0.010372 30.9839 0.649523 33.579 2.01478 35.6513C3.88136 38.48 6.80192 40.0484 10.1853 41.8646L10.204 41.8752C12.9578 43.3539 16.0801 45.0308 18.6357 47.6294L19.0006 48L19.3644 47.6294C21.9282 45.0179 25.0551 43.3433 27.8148 41.8646L27.8346 41.8551C31.2087 40.046 34.1222 38.4847 35.9853 35.6501C37.3459 33.5837 38.0046 30.9863 38 27.7127V0H0.000123214ZM25.8094 9.16123H25.1764L24.3567 9.11403C25.0621 10.031 25.4597 11.1545 25.4597 12.3747C25.4597 15.2801 23.1629 17.9791 19.1569 18.0109C16.1512 18.0109 15.3864 18.6977 15.6604 19.3114C16.6234 21.4745 24.3719 15.9127 25.6858 20.246C26.3329 22.3809 24.0454 23.148 22.3281 23.5032C24.5724 24.2266 25.448 25.9602 25.448 28.7606V28.7913C25.448 32.6313 23.8332 34.4853 19.3516 34.4853C17.4756 34.4853 16.0451 34.1572 15.0133 33.4964C14.9608 33.6616 14.8874 33.8197 14.7941 33.9696C14.6682 34.1737 14.491 34.3531 14.2683 34.5007C14.147 34.5821 13.8299 34.7792 13.4242 34.7603C12.9007 34.7355 12.2944 34.3484 12.2035 33.7678C12.1953 33.7159 12.1254 33.2344 12.4402 32.963C12.7526 32.6915 13.3344 32.7269 13.7891 33.1376C13.9302 33.127 13.9978 33.0739 14.0293 33.0397C14.1039 32.9594 14.119 32.8414 14.1039 32.714C13.3006 31.7829 12.9229 30.4788 12.9229 28.7913V28.7606C12.9229 24.9523 14.8792 23.1137 19.3516 23.1137C19.5533 23.1137 19.748 23.1196 19.938 23.1267C22.7117 23.0807 22.7851 22.3502 22.6137 21.8734C21.8501 19.7622 13.8334 24.9028 12.6325 20.4419C12.1417 18.6187 13.6515 17.4114 15.3806 16.9441C13.8427 15.9138 12.9345 14.2464 12.9345 12.4231C12.9345 9.56483 15.1217 6.77032 19.1709 6.77032H25.8094V9.16123Z" fill={fill}/>
                <path id="Vector_2" d="M22.2864 12.3711C22.2864 14.1094 20.8023 15.2648 19.1467 15.2648C17.5471 15.2648 16.1084 13.9194 16.1084 12.4112C16.1084 10.9621 17.2848 9.51643 19.2143 9.51643C21.2127 9.51643 22.2864 11.2123 22.2864 12.3711Z" fill={fill}/>
                <path id="Vector_3" d="M21.1353 27.4719V30.0186C21.1353 32.0743 20.5721 32.9465 19.1812 32.9465C17.8078 32.9465 17.2354 32.0744 17.2354 30.0186V27.4719C17.2354 25.4928 17.8078 24.6514 19.1812 24.6514C20.5721 24.6514 21.1353 25.4928 21.1353 27.4719Z" fill={fill}/>
            </g>
        </svg>
    );
};

export default LogoIconGregory;
