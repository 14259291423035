/** @format */

import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	useBreakpointValue,
	useMediaQuery,
} from '@chakra-ui/react';
import {Field, Form, Formik} from 'formik';
import Image from 'next/image';
import React, {useCallback, useRef, useState} from 'react';
import * as Yup from 'yup';
import {ModalNewsLetterProps} from './ModalNewsLetterProps';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from 'axios';
import {fonts} from '../../styles/js/fonts';
import {NewsletterFormData} from '../../utils/data';
const mailchimp = require('@mailchimp/mailchimp_marketing');

const API_KEY = process.env.NEXT_PUBLIC_MAILCHIMP_SECRET;
const DATACENTER = API_KEY.split('-')[1];

mailchimp.setConfig({
	apiKey: API_KEY,
	server: DATACENTER,
});

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Too short!')
		.max(20, 'Too long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	agree: Yup.boolean()
		.required('The terms and conditions must be accepted.')
		.oneOf([true], 'The terms and conditions must be accepted.'),
});

const ModalNewsLetter = ({isOpen, onOpen, onClose}: ModalNewsLetterProps) => {
	const initialRef = useRef();
	const finalRef = useRef();
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [hasSuccess, setHasSuccess] = useState(false);
	const [loadingMailChimp, setLoadingMailChimp] = useState(false);
	const {executeRecaptcha} = useGoogleReCaptcha();

	const addRecaptcha = useCallback(
		async values => {
			if (!executeRecaptcha) {
				// console.log('Execute recaptcha not yet available');
				return;
			}

			const gReCaptchaToken = await executeRecaptcha('Subscribe_NewsLetter');
			return gReCaptchaToken;
		},
		[executeRecaptcha],
	);

	const [lg] = useMediaQuery('(min-width: 992px)');

	const size = useBreakpointValue({
		base: 'full',
		lg: '6xl',
	}, {
		ssr: true,
	});

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			onClose={onClose}
			isOpen={isOpen}
			size={size}
			isCentered={lg}
			motionPreset='slideInBottom'
			// blockScrollOnMount={true}
		>
			<ModalOverlay />
			<ModalContent bg='white'>
				<ModalHeader>
					<HStack justifyContent={'space-between'}>
						{/* <Image
							src={'/icons/feather/x.svg'}
							alt=''
							width='184'
							height='36'
							style={{
								maxWidth: '100%',
								height: 'auto',
							}}
						/> */}
						<Box> {NewsletterFormData.label}</Box>
						<Box
							onClick={() => {
								setHasError(false);
								setHasSuccess(false);
								onClose();
							}}>
							Close
							{/* <Image
								alt=''
								src={'/icons/feather/x.svg'}
								width='33'
								height='33'
								style={{
									maxWidth: '100%',
									height: 'auto',
								}}
							/> */}
						</Box>
					</HStack>
				</ModalHeader>

				<Box borderBottom='2px' borderColor='gneiss.200' />

				{!hasError && !hasSuccess ? (
					<DefaultModalBody
						onClose={onClose}
						initialRef={initialRef}
						setHasSuccess={setHasSuccess}
						setHasError={setHasError}
						loadingMailChimp={loadingMailChimp}
						setLoadingMailChimp={setLoadingMailChimp}
						setErrorMessage={setErrorMessage}
						addRecaptcha={addRecaptcha}
					/>
				) : null}
				{hasError ? (
					<ErrorModalBody
						onClose={onClose}
						onOpen={onOpen}
						setHasError={setHasError}
						errorMessage={errorMessage}
					/>
				) : null}
				{hasSuccess ? (
					<SuccessfulModalBody
						onClose={onClose}
						setHasError={setHasError}
						setHasSuccess={setHasSuccess}
					/>
				) : null}
			</ModalContent>
		</Modal>
	);
};

export default ModalNewsLetter;

const DefaultModalBody = ({
	onClose,
	initialRef,
	setHasSuccess,
	setHasError,
	loadingMailChimp,
	setLoadingMailChimp,
	setErrorMessage,
	addRecaptcha,
}) => {
	return (
		<ModalBody pb='53px'>
			{NewsletterFormData.paragraphs.map((paragraph, index) => {
				return (
					<Text
						key={index}
						py={{base: '16px', lg: '20px'}}
						textStyle={{
							base: fonts.copernicusGalaxyBook,
						}}
						whiteSpace='pre-line'
						w='100%'>
						{paragraph}
					</Text>
				);
			})}

			<Text
				textStyle={{
					base: fonts.copernicusGalaxyBook,
				}}
				whiteSpace='pre-line'
				w='100%'>

			</Text>
			<Text
				py={{base: '16px', lg: '20px'}}
				textStyle={{
					base: fonts.copernicusGalaxyBook,
				}}
				whiteSpace='pre-line'
				w='100%'>

			</Text>

			<Formik
				initialValues={{
					name: '',
					email: '',
					agree: false,
					gReCaptchaToken: '',
				}}
				validationSchema={ContactSchema}
				onSubmit={async (values, {resetForm}) => {
					try {
						const gReCaptchaToken = await addRecaptcha(values);
						setLoadingMailChimp(true);
						values.gReCaptchaToken = gReCaptchaToken;
						const validateReCaptchaResponse = await axios.post(
							'lib/api/validateReCaptcha',
							values,
						);
						if (validateReCaptchaResponse.status === 200) {
							const subscribe = async () => {
								const response = await fetch('lib/api/subscribe', {
									body: JSON.stringify({
										email: values.email,
										name: values.name,
										gReCaptchaToken,
									}),
									headers: {
										'Content-Type': 'application/json',
									},
									method: 'POST',
								});

								const {error} = await response.json();
								if (error) throw error;
								return response;
							};

							const response = await subscribe();

							if (response && response.ok) {
								setLoadingMailChimp(false);
								setHasSuccess(true);
								resetForm();
							}
						} else {
							throw new Error();
						}
					} catch (error) {
						setHasError(true);
						setLoadingMailChimp(false);
						if (error === 'Member Exists') {
							setErrorMessage(
								'You have already subscribed to this newsletter. Please check your inbox to find our newsletters.',
							);
						}

						console.error('mailchimp api fail', error);
					}
				}}>
				{({errors, touched}) => (
					<Form>
						<div id='recaptcha-widget'></div>

						<Stack direction={{base: 'column', lg: 'row'}} spacing='16px'>
							<Box flex={{base: '1', lg: '1'}} pt='32px'>
								<Field name='name'>
									{({field, form}) => (
										<FormControl
											isInvalid={form.errors.name && form.touched.name}>
											<Input
												name='name'
												type='text'
												{...field}
												ref={initialRef}
												placeholder='Name'
											/>
											<FormErrorMessage>{form.errors.name}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</Box>
							<Box flex={{base: '1', lg: '1'}} pt='32px'>
								<Field name='email'>
									{({field, form}) => (
										<FormControl
											isInvalid={form.errors.email && form.touched.email}>
											<Input
												name='email'
												type='text'
												{...field}
												placeholder='Email Address'
											/>
											<FormErrorMessage>{form.errors.email}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</Box>
						</Stack>

						<Stack
							direction={{base: 'column', lg: 'row'}}
							spacing='16px'
							pt={{base: '16px', lg: '33px'}}>
							<Box flex={{base: '1', lg: '1'}}>
								<Field name='agree'>
									{({field, form}) => (
										<FormControl isInvalid={form.errors.agree}>
											<Checkbox
												size='md'
												colorScheme='blue'
												{...field}
												name='agree'>
												<Text
													textStyle={{
														base: `paragraph2LeftDark`,
													}}>
													I agree that I have read the{' '}
													<Link href='/terms-and-conditions' variant='native'>
														privacy policy
													</Link>{' '}
													and consent to administrator contacting me.
												</Text>
											</Checkbox>
											<FormErrorMessage>{form.errors.agree}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</Box>
							<Box>
								<Button
									mt='40px'
									type='submit'
									isLoading={loadingMailChimp}
									spinnerPlacement='end'
									loadingText={'Signing Up'}
									colorScheme='blue'
									w={{base: '100%', lg: 'auto'}}>
									Sign Up{' '}
								</Button>
							</Box>
						</Stack>
					</Form>
				)}
			</Formik>
		</ModalBody>
	);
};

const ErrorModalBody = ({onClose, onOpen, setHasError, errorMessage}) => {
	return (
		<ModalBody pb='53px'>
			<Text
				pt='34px'
				textStyle={{
					base: `display6LeftDark`,
				}}
				whiteSpace='pre-line'
				w='100%'>
				It looks like something went wrong…
			</Text>

			{errorMessage ? (
				<Text
					py={{base: '16px', lg: '20px'}}
					textStyle={{
						base: `paragraph1LeftDark`,
					}}
					whiteSpace='pre-line'
					w='100%'>
					{errorMessage}
				</Text>
			) : (
				<Text
					py={{base: '16px', lg: '20px'}}
					textStyle={{
						base: `paragraph1LeftDark`,
					}}
					whiteSpace='pre-line'
					w='100%'>
					Sorry! It looks like something went wrong, please try again later.
				</Text>
			)}

			<Stack
				direction={{base: 'column', lg: 'row'}}
				spacing='16px'
				pt={{base: '16px', lg: '33px'}}>
				<Box justifyContent={'flex-end'}>
					<ButtonGroup spacing='16px' mt='40px'>
						<Button
							colorScheme='blue'
							onClick={() => {
								setHasError(false);
								onOpen();
							}}
							w={{base: '100%', lg: 'auto'}}>
							Try Again
						</Button>
						<Button
							variant='outline'
							colorScheme='blue'
							onClick={() => {
								setHasError(false);
								onClose();
							}}
							w={{base: '100%', lg: 'auto'}}>
							Done
						</Button>
					</ButtonGroup>
				</Box>
			</Stack>
		</ModalBody>
	);
};

const SuccessfulModalBody = ({onClose, setHasError, setHasSuccess}) => {
	return (
		<ModalBody pb='53px'>
			<Text
				pt='34px'
				textStyle={{
					base: `display6LeftDark`,
				}}
				whiteSpace='pre-line'
				w='100%'>
				Thanks for Subscribing!
			</Text>

			<Text
				py={{base: '16px', lg: '20px'}}
				textStyle={{
					base: `paragraph1LeftDark`,
				}}
				whiteSpace='pre-line'
				w='100%'>
				Thanks for subscribing! We will be in touch soon with an update from the
				Administrator team.
			</Text>

			<Stack
				direction={{base: 'column', lg: 'row'}}
				spacing='16px'
				pt={{base: '16px', lg: '33px'}}>
				<Box justifyContent={'flex-end'}>
					<Button
						mt='40px'
						colorScheme='blue'
						onClick={() => {
							setHasError(false);
							setHasSuccess(false);
							onClose();
						}}
						w={{base: '100%', lg: 'auto'}}>
						Done
					</Button>
				</Box>
			</Stack>
		</ModalBody>
	);
};
