/** @format */

import {getButtonMapping, removeTypenameProp} from '../../utils';

export const ctaBannerMapping = (data: any) => {
	if (!data) return null;
	let cleanedQuery = removeTypenameProp(data);

	const {
		subtitles = [],
		backgroundGroup: {backgroundColour, backgroundType, image},
		ctas = [],
	} = cleanedQuery;

	let bg = '';
	if (backgroundColour) bg = backgroundColour.backgroundColour;

	const mappedCtas = ctas?.map(cta => {
		return {
			opensForm: !!cta.form,
			formName: !!cta.formName,
			buttons: getButtonMapping(cta.buttonGroup),
		};
	});

	return {
		variant: data.variant,
		title: data.title,
		subtitles: subtitles?.map(item => item.subtitles) || [],
		isFullBleed: data.displayFullBleed,
		includeBackgroundImage: backgroundType === 'image',
		backgroundImage: image
			? {
					src: image.sourceUrl,
			  }
			: undefined,
		backgroundColour: bg,
		ctas: mappedCtas || [],
	};
};
