/** @format */

import {gql} from '@apollo/client';
import {
	Container,
	Box,
	Flex,
	Grid,
	Heading,
	HStack,
	VStack,
	Text,
	LinkBox,
	LinkOverlay,
	Button,
	Center,
	Tag,
	useBreakpointValue,
	useBreakpoint,
	SimpleGrid,
} from '@chakra-ui/react';
import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';
import {getApolloClient} from '../../lib/api/apollo-client';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import {getDomainFontFamily, getMostFrequent} from '../../utils';
import LogoIcon from '../LogoIcon';
import {ArrowRight, File} from 'react-feather';
import {fonts} from '../../styles/js/fonts';
import {fontSizes} from '../../styles/js/typography';
import Link from 'next/link';

const client = getApolloClient('harlaxton');

interface LinkProps {
	url: string;
	target?: string;
}

interface CtaCardsProps {
	title: string;
	cards: CtaCardProps[];
	subdomain: string;
	link?: LinkProps;
	isCardLinks?: boolean;
}

interface CtaCardProps {
	title: string;
	cardHeight?: string;
	background: {
		backgroundType: string;
		backgroundColour: string;
		image: {
			src: string;
			alt: string;
		};
	};
	includeButton?: boolean;
	buttons?: {
		style: string;
		url: string;
		target?: string;
		colorScheme?: string;
		title: string;
	}[];
	link?: LinkProps;
	links?: [LinkProps];
	index?: number;
	hoveredCardIndex?: number;
	setHoveredCardIndex?: React.Dispatch<React.SetStateAction<number | null>>;
	isCardLinks?: boolean;
	showLogo?: boolean;
	rounded: string;
	textStylesSelected?: any;
	subdomain: string;
}

const getCardTheme = (background, buttons) => {
	const backgroundColour = 'aqua.500';

	const cardTheme = {
		'maroon.500': {
			cardHoverBg: colors.maroon[600],
			color: colors.pink[500],
		},
		'pink.500': {
			cardHoverBg: colors.pink[600],
			color: colors.maroon[500],
		},
		'pine.500': {
			cardHoverBg: colors.pine[600],
			color: colors.pink[500],
		},
		'pine.700': {
			cardHoverBg: colors.pine[800],
			color: colors.pink[500],
		},
		'aqua.500': {
			cardHoverBg: colors.aqua[600],
			color: colors.greyWarm[500],
		},
		'aqua.50': {
			cardHoverBg: colors.aqua[100],
			color: colors.aqua[500],
		},
	};

	return cardTheme['aqua.500'];
};

const getPosts = async first => {
	const {data} = await client.query({
		query: gql`
			query getPosts($first: Int) {
				newsItems(first: $first) {
					nodes {
						title
						slug
						excerpt
						featuredImage {
							node {
								sourceUrl
								altText
							}
						}
						author {
							node {
								name
								username
								firstName
								lastName
							}
						}
						date
						uri
						categories {
							nodes {
								slug
								name
							}
						}
					}
				}
			}
		`,
		variables: {
			first,
		},
	});

	return data.newsItems.nodes;
};

export const getDomainColour = category => {
	let backgroundColour = colors.gold[100];
	let foregroundColour = colors.gold[500];
	let badgeBackgroundColour = colors.gold[100];
	let badgeForegroundColour = colors.gold[600];

	if (category) {
		if (category.toLowerCase().includes('college')) {
			backgroundColour = colors.aqua[100];
			foregroundColour = colors.aqua[500];
			badgeBackgroundColour = colors.aqua[100];
			badgeForegroundColour = colors.aqua[500];
		}
		if (category.toLowerCase().includes('garden')) {
			backgroundColour = colors.pine[100];
			foregroundColour = colors.pine[500];
			badgeBackgroundColour = colors.green[100];
			badgeForegroundColour = colors.pine[500];
		}
		if (category.toLowerCase().includes('harlaxton')) {
			backgroundColour = colors.gold[100];
			foregroundColour = colors.gold[500];
			badgeBackgroundColour = colors.gold[100];
			badgeForegroundColour = colors.gold[600];
		}
	}

	return {
		foreground: foregroundColour,
		background: backgroundColour,
		badgeBackground: badgeBackgroundColour,
		badgeForeground: badgeForegroundColour,
	};
};

const PostGrid = ({subdomain = ''}) => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const cardHeight = '22.5rem';
	const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);
	const [index, setIndex] = useState(4);
	const [isComplete, setIsComplete] = useState(false);
	const nextPosts = 4;
	const loadMoreButtonRef = useRef(null);

	useEffect(() => {
		const fetchPosts = async () => {
			const posts = await getPosts(index);

			setPosts(posts);
			setLoading(false);
		};

		fetchPosts();
	}, []);

	const loadMore = () => {
		setIndex(index + nextPosts);

		const fetchMorePosts = async () => {
			const posts = await getPosts(index + nextPosts);

			setPosts(posts);
			setLoading(false);
			scrollToElement();
		};
		fetchMorePosts();

		// if (index >= posts.length) {
		// 	console.log('Now complete.');
		// 	setIsComplete(true);
		// } else {
		// 	setIsComplete(false);
		// }
	};

	const scrollToElement = () => {
		loadMoreButtonRef.current?.scrollIntoView({behavior: 'smooth'});
	};

	return (
		<Box bg='white' as='section' overflow='hidden' position='relative'>
			<Container
				position='relative'
				maxW={{base: 'container.xl', lg: '1440px'}}
				mx='auto'>
				<SimpleGrid columns={{sm: 1, md: 2, lg: 3, xl: 4}} spacing={2} mt={4}>
					{posts.map((newsItem, i) => (
						<>
							<PostCard newsItem={newsItem} subdomain={subdomain} index={i} />
						</>
					))}
				</SimpleGrid>
				<Center pt={2} pb={8}>
					{posts.length && !isComplete ? (
						<Button
							ref={loadMoreButtonRef}
							variant={'outline'}
							fontSize={'16px'}
							color={getDomainColour(subdomain).foreground}
							borderColor={getDomainColour(subdomain).foreground}
							width={{base: '100%', md: 'initial'}}
							onClick={loadMore}
							mt='6'>
							load more news
						</Button>
					) : null}
				</Center>
			</Container>
		</Box>
	);
};

const Divider = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='4'
		height='4'
		viewBox='0 0 4 4'
		fill='none'>
		<circle cx='2' cy='2' r='2' fill='#000' />
	</svg>
);

const PostCard = ({newsItem, subdomain, index}) => {
	const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);

	let displayCard = false;
	if (subdomain && subdomain !== 'harlaxton') {
		newsItem.categories.nodes.map(catItem => {
			if (catItem.slug) {
				if (catItem.slug.includes(subdomain)) {
					displayCard = true;
				}
			}
		});
	} else {
		displayCard = true;
	}

	if (displayCard) {
		const strippedExcerpt = newsItem.excerpt.replace(/(<([^>]+)>)/gi, '');
		let imageUrl = '';
		let altText = '';
		if (newsItem?.featuredImage) {
			imageUrl = newsItem.featuredImage.node.sourceUrl;
			altText = newsItem.featuredImage.node.altText;
		}
		const image = {
			src: imageUrl,
			alt: altText,
		};

		const formattedDate = new Date(newsItem.date).toLocaleDateString('en-US', {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		});
		const postUri = newsItem.uri;
		const newsHomeDomain = process.env.NEXT_PUBLIC_APP_URL;
		const {firstName, lastName, name} = newsItem.author.node;

		return (
			<Box
				bg='white'
				p='6'
				position={'relative'}
				onMouseEnter={() => setHoveredCardIndex(index)}
				onMouseLeave={() => setHoveredCardIndex(null)}>
				<Flex direction='column' height={'100%'}>
					<Link href={`${newsHomeDomain}${postUri}`} passHref>
						<Text display={'none'}>{`${newsHomeDomain}${postUri}`}</Text>
						<LinkBox>
							<Box overflow={'hidden'} position={'relative'} height={200}>
								{image.src && (
									<>
										<Image
											src={image.src}
											alt={image.alt}
											width={400}
											height={300}
											style={{
												objectFit: 'cover',
												height: '200px',
												transition: 'transform 0.25s ease-in-out',
												transform:
													hoveredCardIndex === index
														? 'scale(1.10)'
														: 'scale(1)',
											}}
										/>
									</>
								)}
								<Box
									overflow={'hidden'}
									position={'relative'}
									height={200}
									backgroundColor={getDomainColour(subdomain).background}></Box>
							</Box>

							<HStack
								mt='5'
								spacing='3'
								alignItems={'flex-start'}
								minH={'30px'}>
								{newsItem.categories.nodes.map(catItem => (
									<Tag
										key={`${index}-${catItem.name}`}
										variant='solid'
										borderRadius={0}
										backgroundColor={
											getDomainColour(catItem.name).badgeBackground
										}
										color={getDomainColour(catItem.name).badgeForeground}
										fontFamily={fonts.copernicusGalaxyMedium}
										fontSize={fontSizes.xs}>
										{catItem.name}
									</Tag>
								))}
							</HStack>
							<Heading
								my='4'
								size='lg'
								fontFamily={fonts.copernicusGalaxyBook}
								fontSize={fontSizes.md}>
								{newsItem.title}
							</Heading>
							<Text pb={2} fontFamily={fonts.copernicusGalaxyMedium}>
								{strippedExcerpt}
							</Text>
							<Flex direction='row' alignItems={'center'} gap={2}>
								<Text
									fontFamily={fonts.copernicusGalaxyBook}
									fontSize={fontSizes.xs}>
									{firstName && lastName ? `${firstName} ${lastName}` : name}
								</Text>
								<Divider />
								<Text
									fontFamily={fonts.copernicusGalaxyBook}
									fontSize={fontSizes.xs}>
									{formattedDate}
								</Text>
							</Flex>
						</LinkBox>
					</Link>
				</Flex>
			</Box>
		);
	} else {
		return null;
	}
};

export default PostGrid;
