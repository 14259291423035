/** @format */

import {Box, Button, Container, Flex, Text} from '@chakra-ui/react';
import {textStyles} from '../../styles/js/textStyles';
import RichTextCta from '../RichTextCta';
import {linkResolver} from '../../lib/linkResolver';
import {decode} from 'html-entities';
import {zIndices} from '../../styles/js/zIndices';

export type SubNavigationProps = {
	richTextCta: any;
	subdomain: string;
	title: string;
	links: {
		url: string;
		title: string;
	}[];
};

const subdomainColorMap = (
	subdomain: string,
): {
	titleBg: string;
	linkBg: string;
} => {
	switch (subdomain) {
		case 'college':
			return {
				titleBg: 'aqua.600',
				linkBg: 'aqua.800',
			};
		case 'events':
			return {
				titleBg: 'gold.500',
				linkBg: 'gray.700',
			};
		case 'garden':
			return {
				titleBg: 'pine.500',
				linkBg: 'pine.700',
			};
		default:
			return {
				titleBg: 'aqua.600',
				linkBg: 'aqua.800',
			};
	}
};

const Icon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'>
		<path
			d='M7.5 15L12.5 10L7.5 5'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const SubNavigation = ({
	richTextCta,
	title,
	links,
	subdomain,
}: SubNavigationProps) => {
	const colors = subdomainColorMap(subdomain);

	return (
		<Box as='section' position='relative'>
			<Container maxW='container.xl' mx='auto'>
				<Flex direction={{base: 'column-reverse', md: 'row'}}>
					<RichTextCta {...richTextCta} container={false} />
					<Box mt={{base: '1.5rem', md: '-64px'}}>
						<Box
							w={{base: '100%', lg: '400px'}}
							zIndex={{base: 'auto', md: zIndices.docked}}>
							<Box bg={colors.titleBg} px={'1.5rem'} py={4}>
								<Text color='white' {...textStyles.textmd}>
									{title}
								</Text>
							</Box>
							{links && (
								<Flex
									direction='column'
									p={'1.5rem'}
									gap={'1.5rem'}
									bg={colors.linkBg}
									alignItems='flex-start'>
									{links.map((link, index) => {
										return (
											<Flex
												key={`${link.url}-${index}`}
												gap={2}
												alignItems='center'
												justifyContent='center'>
												<Icon />
												<Button
													as='a'
													href={linkResolver(link.url)}
													bg={colors.linkBg}
													variant='link'
													textColor='white'
													{...textStyles.textsm}>
													{decode(link.title)}
												</Button>
											</Flex>
										);
									})}
								</Flex>
							)}
						</Box>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default SubNavigation;
