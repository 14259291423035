import { removeTypenameProp } from "../../utils";
import { getMedia } from "../Image/imageMapping";
import { getTextComponentMapping } from "../TextComponent/textComponentMapping";

export const imageTextMapping = (data: any) => {
    if (!data) return null;
    const cleanedQuery = removeTypenameProp(data);
    const {
        imageGroup: { image, shieldLogo },
        textAlignment = "left",
        textComponent,
    } = cleanedQuery;

    const imageTextMappingData = {
        textAlignment,
        image: getMedia(image),
        shieldLogo,
        textComponent: getTextComponentMapping(textComponent),
    };

    return imageTextMappingData;
};
