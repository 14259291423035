import DOMPurify from "isomorphic-dompurify";
import { removeTypenameProp } from "../../utils";
import { getMedia } from "../Image/imageMapping";

export default (data: any) => {
    if (!data) return null;
    const cleanedQuery = removeTypenameProp(data);

    const { title = "", bioCards = [] } = cleanedQuery;

    const bioCardsMappingData = {
        title,
        cards: bioCards.map((card: any) => {
            const { image, ...otherCardProps } = card;
            const defaultStringProps = Object.keys(otherCardProps).reduce(
                (props: any, key: string) => {
                    const fieldValue = otherCardProps[key];
                    props[key] = fieldValue || "";
                    if (key === "wysiwyg") {
                        props["html"] = {
                            __html: DOMPurify.sanitize(fieldValue),
                        };
                    }
                    //typeof fieldValue==='string'
                    return props;
                },
                {}
            );

            return {
                image: getMedia(image),
                ...defaultStringProps,
            };
        }),
    };

    return bioCardsMappingData;
};
