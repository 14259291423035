/** @format */

import {getButtonMapping} from '../../utils';

export const getTextComponentMapping = (data: any) => {
	if (!data) return null;

	const {
		title = '',
		titleCategory = '',
		subtitles = [],
		includeButton = false,
		buttons = [],
	} = data;

	const mappedData = {
		title,
		titleCategory,
		subtitles:
			subtitles?.map(item => item?.subtitles?.split('\r\n'))?.flat() || [],
		buttons: includeButton ? getButtonMapping(buttons) : [],
	};

	return mappedData;
};
