/** @format */

import {SubNavigationProps} from '.';
import {getButtonMapping} from '../../utils';
import {richTextCtaMapping} from '../RichTextCta/richTextCtaMapping';

const subNavigationMapping = (data: any): SubNavigationProps => {
	const {
		addButtons,
		richText,
		subdomain,
		title,
		links,
		buttonGroup = [],
	} = data;

	const subNavigationLinks = links.map((link: any) => {
		const {url, title} = link.link;
		return {
			url,
			title,
		};
	});

	return {
		richTextCta: richTextCtaMapping({
			addButtons,
			richText,
			buttonGroup,
		}),
		subdomain,
		title,
		links: subNavigationLinks,
	};
};

export default subNavigationMapping;
