/** @format */

import {
	AspectRatio,
	Box,
	Container,
	Flex,
	HStack,
	Stack,
	Tab,
	Tabs,
	Text,
	useBreakpoint,
	useBreakpointValue,
} from '@chakra-ui/react';
import {useState} from 'react';
import {colors} from '../../styles/js/colors';
import {textStyles} from '../../styles/js/textStyles';
import {getDomainColorScheme, getDomainFontFamily} from '../../utils';
import Image from 'next/image';
import {decode} from 'html-entities';

type RoomCarouselProps = {
	showSectionHeading: boolean;
	sectionHeading?: string;
	roomRepeater: {
		tabLabel: string;
		title: string;
		subtitle: string;
		description: any;
		image: {
			sourceUrl: string;
			altText: string;
			mediaDetails: {
				height: number;
				width: number;
			};
		};
	}[];
	subdomain: string;
};

const RoomCarousel = ({
	showSectionHeading,
	sectionHeading,
	roomRepeater,
	subdomain,
}: RoomCarouselProps) => {
	const breakpoint = useBreakpoint();
	const domainFontFamily = getDomainFontFamily(subdomain);
	const colorScheme = getDomainColorScheme(subdomain);

	const textStylesSelected = {
		...useBreakpointValue(
			{ base: textStyles.textxl, lg: textStyles.text2Xl },
			{ ssr: true },
		),
	};
	const textStylesSelectedh3 = {
		...useBreakpointValue(
			{ base: textStyles.textlg, lg: textStyles.text2Xl },
			{ ssr: true },
		),
	};
	const textStylesSelectedh4 = {
		...useBreakpointValue({ base: textStyles.headingsm }, { ssr: true }),
	};

	const [selectedTab, setSelectedTab] = useState(0);

	const TabsLayout = () => {
		if (breakpoint === 'base' || breakpoint === 'sm') {
			return (
				<HStack className='tab-label' w={'100%'} overflow={'scroll'}>
					{roomRepeater.map((room, index) => (
						<Text
							key={index}
							px={4}
							py={3}
							bg={selectedTab === index ? colors[colorScheme][500] : 'white'}
							color={selectedTab === index ? 'white' : 'black'}
							onClick={() => setSelectedTab(index)}
							style={subdomain === 'thegregory' ? textStyles.headingsmGillSans : textStyles.headingsm}
							cursor='pointer'
							w='fit-content'>
							{room.tabLabel}
						</Text>
					))}
				</HStack>
			);
		} else {
			return (
				<Stack w='228px' direction={'column'} spacing={2}>
					{roomRepeater.map((room, index) => (
						<Text
							key={index}
							px={4}
							py={3}
							bg={selectedTab === index ? colors[colorScheme][500] : 'white'}
							color={selectedTab === index ? 'white' : 'black'}
							onClick={() => setSelectedTab(index)}
							style={subdomain === 'thegregory' ? textStyles.textxsGillSans : textStyles.headingsm}
							sx={{
								textTransform: subdomain === 'thegregory' ? 'uppercase' : '',
								}}
							cursor='pointer'
							w='fit-content'>
							{room.tabLabel}
						</Text>
					))}
				</Stack>
			);
		}
	};

	return (
		<Box as='section' overflow='hidden' position='relative' py={{base: '4rem'}}>
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Flex direction='column' gap={8}>
					{showSectionHeading && (
						<Text
							as='h2'
							{...textStylesSelected}
							sx={{
								textTransform: subdomain === 'thegregory' ? 'uppercase' : '',
								}}
							fontFamily={domainFontFamily}
							color={colors[colorScheme][500]}>
							{decode(sectionHeading)}
						</Text>
					)}
					{roomRepeater[selectedTab] ? (
						<Flex
							alignItems='center'
							direction={
								breakpoint === 'base' || breakpoint === 'sm' ? 'column' : 'row'
							}>
							<TabsLayout />
							<AspectRatio ratio={16 / 9} w='100%'>
								<Image
									src={roomRepeater[selectedTab].image.sourceUrl}
									alt={roomRepeater[selectedTab].image.altText}
									fill
								/>
							</AspectRatio>
						</Flex>
					) : null}

					{roomRepeater[selectedTab] ? (
						<Flex
							gap={14}
							pl={
								breakpoint === 'base' || breakpoint === 'sm'
									? '0px'
									: '202.78px'
							}
							direction={
								breakpoint === 'base' ||
								breakpoint === 'sm' ||
								breakpoint === 'md'
									? 'column'
									: 'row'
							}>
							<Flex direction='column' gap={4} w='fit-content'>
								<Text as='h3'
									{...subdomain === 'thegregory' ? textStyles.headingxlGillSans : textStylesSelectedh3}
									sx={{
										textTransform: subdomain === 'thegregory' ? 'uppercase' : '',
									}}>
									{roomRepeater[selectedTab].title}
								</Text>
								<Text as='h4' {...subdomain === 'thegregory' ? textStyles.headingsmGillSans : textStylesSelectedh4}>
									{roomRepeater[selectedTab].subtitle}
								</Text>
							</Flex>
							<Box
								dangerouslySetInnerHTML={{
									__html: roomRepeater[selectedTab].description,
								}}
								className={`WYSIWYG--default carousel ${subdomain}`}
								maxW={
									breakpoint === 'base' ||
									breakpoint === 'sm' ||
									breakpoint === 'md'
										? '100%'
										: '60%'
								}
							/>
						</Flex>
					) : null}
				</Flex>
			</Container>
		</Box>
	);
};

export default RoomCarousel;
